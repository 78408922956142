import Vue from 'vue'
import App from './App.vue'
import VueFlashMessage from 'vue-flash-message'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.css'

require('vue-flash-message/dist/vue-flash-message.min.css');

Vue.config.productionTip = false

Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
    pauseOnInteract: true
  }
})

Vue.use(BootstrapVue)

new Vue({
  render: h => h(App),
}).$mount('#app')
