<template>
  <div class="box">
    <input type="file" accept="image/*,application/pdf" @change="changeFile" id='file' name='qr' class='inputfile inputfile-1'/>
    <label for="file">
      <img src="../assets/img/upload3.png" alt="upload" style="height: 40px;" v-if="showQR">
      <b-spinner style="width: 40px; height: 40px; margin-right: 0.75em; vertical-align: middle;" variant="white" v-if="!showQR"></b-spinner>
      <span>Загрузить QR-код</span>
    </label>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Download',
    data () {      
      return {
        showQR: true
      }
    },
    methods: {
      changeFile() {
        this.showQR = !this.showQR

        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("qr", imagefile.files[0]);

        axios.post('/api/check', formData, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          let blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' }),
          url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = 'pass.pkpass'
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.showQR = !this.showQR
        }).catch(error => {
          this.showQR = !this.showQR
          error.response.data.text()
            .then(result => {
              let error = JSON.parse(result)
              console.log(error.message)
              this.flash(error.message, 'error')
            }).catch(error => {
              console.log(error)
              this.flash("Непредвиденная ошибка сервиса", 'error')
            })
        })
        document.querySelector('#file').value = null
      }
    }
  }
</script>

<style scoped>
#file {
  display: none;
}
.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    display: none;
}

.inputfile + label {
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    border-radius: 14px;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile-1 + label {
    color: #f1e5e6;
    background-color: #5b30ea;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #a01ae2;
}

.inputfile + label img {
    vertical-align: middle;
    fill: currentColor;
    margin-right: 0.75em;
}
</style>
