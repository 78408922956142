<template>
  <footer class="bg-black text-center py-5">
    <div class="container px-5">
      <div class="text-white-50 small">
        <a href="https://t.me/CovidQrWalletBot" target="_blank">CovidQrWalletBot</a>
        <span class="mx-1">&middot;</span>
        <a href="https://www.tinkoff.ru/cf/7u398TfHMYC" target="_blank">Поблагодарить автора</a>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>
